<template>
	<div class="testSwiper app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="轮播图" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="testSwiper_body app_scroll">
			<!-- swiper1 -->
			<div class="title">基本效果-小圆点和左右切换</div>
			<div class="swiper-container swiper1">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
				</div>
				<!-- 如果需要分页器 -->
				<div class="swiper-pagination"></div>

				<div class="swiper-button-prev"></div>
				<!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
				<div class="swiper-button-next"></div>
				<!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
			</div>

			<!-- swiper2 -->
			<div class="title">切换效果-effect</div>
			<div class="swiper-container swiper2">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
				</div>
			</div>

			<!-- swiper3 -->
			<div class="title">切换效果-cube</div>
			<div class="swiper-container swiper3">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
					<div class="swiper-slide">
						<img src="~@/assets/images/project/img_001.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		defineComponent,
		onMounted
	} from "vue";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import Swiper, {
		Autoplay,
		EffectCoverflow,
		EffectCube,
		Pagination,
		Navigation,
	} from "swiper";
	Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);

	// swiper-bundle.min.css 决定了小圆点和左右翻页标签，如果不需要可以不引用
	import "swiper/swiper-bundle.min.css";

	// swiper.less/sass/css 决定了基础的样式
	import "swiper/swiper.scss";

	export default defineComponent({
		name: "TestSwiper",
		components: {},
		setup() {
			onMounted(() => {
				new Swiper(".swiper1", {
					pagination: {
						el: ".swiper-pagination",
					},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
						hideOnClick: true,
					},
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					on: {
						navigationShow: function() {
							console.log("按钮显示了");
						},
					},
				});

				new Swiper(".swiper2", {
					//循环
					loop: true,
					//每张播放时长3秒，自动播放
					spaceBetween: 16,
					// 切换效果 
					effect: "coverflow",
					// 该选项给Swiper用户提供小小的贴心应用，设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
					grabCursor: true,
					// 设定为true时，active slide会居中，而不是默认状态下的居左。
					centeredSlides: true,
					// 设置slider容器能够同时显示的slides数量(carousel模式)。
					slidesPerView: 1.32,
					// 自动切换
					autoplay: {
						// 自动切换的时间间隔
						delay: 30000,
						// 如果设置为true，当切换到最后一个slide时停止自动切换
						stopOnLastSlide: false,
						// 用户操作swiper之后，是否禁止autoplay。默认为true：停止
						disableOnInteraction: false,
					},
					// 类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式
					coverflowEffect: {
						// slide做3d旋转时Y轴的旋转角度
						rotate: 0,
						// 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
						stretch: -70,
						// slide的位置深度。值越大z轴距离越远，看起来越小。
						depth: 500,
						// depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
						modifier: 1,
						// 是否开启slide阴影
						slideShadows: true,
					},
				});

				new Swiper(".swiper3", {
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					effect: "cube",
					cubeEffect: {
						slideShadows: true,
						shadow: true,
						shadowOffset: 100,
						shadowScale: 0.6,
					},
				});
			});
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				fLeft
			}
		},
	});
</script>

<style lang="scss" scoped>
	.title {
		text-align: center;
		line-height: 50px;
	}

	.swiper-slide {
		width: 7.4666rem;
		height: 4.1066rem;
		background: #FFFFFF;
		border-radius: 0.4266rem;
		opacity: 0.53;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.swiper-slide-active {
		opacity: 1;
	}
</style>
