<template>
	<div class="Test app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="测试页面" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="Test_body app_scroll">
			<van-cell-group>
				<van-cell title="轮播图" is-link @click="fGoPath('testSwiper')" />
				<van-cell title="竞彩足球" is-link @click="fGoPath('testFootball')" />
				<van-cell title="竞彩篮球" is-link @click="fGoPath('testBasketball')" />
				<van-cell title="模版页面-缓存" is-link @click="fGoPath('testTemplateCache')" />
				<van-cell title="JS方法测试页面" is-link @click="fGoPath('testJs')" />
				<van-cell title="剪切板" is-link @click="fGoPath('testClipboard')" />
			</van-cell-group>
		</div>
	</div>
</template>

<script>
	import {
		onActivated,
		inject
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	export default {
		name: "Test",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const fGlobalReload = inject("fGlobalReload");
			
			const fGoPath = (path) => {
				router.push({
					path
				})
			};
			
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				fGoPath,
				
				fLeft
			}
		}
	}
</script>

<style scoped lang="scss">
	.van-cell {
		justify-content: space-between;
	}
</style>
