<template>
	<div class="testClipboard app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="剪切板" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="testClipboard app_scroll">
			<van-button type="primary" @click="fGetClipboard">处理剪切板内容</van-button>
		</div>
	</div>
	<h-dialog-a title="跟单信息" dialogKey="dialogA" :show="oDialogA.showDialog"
		:showCancelButton="oDialogA.showCancelButton" :confirmButtonText="oDialogA.confirmButtonText"
		@fBeforeClose="fBeforeClose">
		<div class="home_planInfo">
			<div class="home_planInfo_title">
				<div class="home_planInfo_title_border"></div>
				<span class="home_planInfo_title_a">{{oPlanInfo.planDetail.lotteryPlanType}}</span>
				<span class="home_planInfo_title_b">{{oPlanInfo.planDetail.lotteryPlanSubType}}</span>
				<span class="home_planInfo_title_d" v-if="oPlanInfo.planDetail.bonusOptimizedSign">奖金优化</span>
				<span class="home_planInfo_title_c"
					v-else-if="oPlanInfo.planDetail.mixTypeList.length <= 1">{{oPlanInfo.planDetail.mixTypeList[0]}}</span>
				<span class="home_planInfo_title_c" v-else>多串</span>
			</div>

			<div class="home_planInfo_user">
				<img class="home_planInfo_user_headPortrait"
					:src="oPlanInfo.roleDetail.head ? oPlanInfo.roleDetail.head : defaultAvatar" alt="">
				<div class="home_planInfo_user_info">
					<div class="home_planInfo_user_info_top">
						<span class="home_planInfo_user_info_top_name">{{oPlanInfo.roleDetail.roleName}}</span>
						<div class="home_planInfo_user_info_top_red" v-if="oPlanInfo.roleDetail.evenRedCount">
							<img class="home_planInfo_user_info_top_red_img" src="~@/assets/icon/icon-001.png" alt="">
							<em>{{oPlanInfo.roleDetail.evenRedCount}}</em><span>连红</span>
						</div>
					</div>
					<p class="home_planInfo_user_info_time">
						截止时间：{{fnFormatData(oPlanInfo.planDetail.stopCopy,"MM-dd hh:mm")}}</p>
				</div>
			</div>
		</div>
	</h-dialog-a>
	<h-dialog-a title="投注信息" dialogKey="dialogB" :show="oDialogB.showDialog"
		:showCancelButton="oDialogB.showCancelButton" :confirmButtonText="oDialogB.confirmButtonText"
		@fBeforeClose="fBeforeClose">
		<div class="home_betInfo">
			<div class="home_betInfo">
				<div class="home_betInfo_title">
					<div class="home_betInfo_title_border" :class="oBetInfo.lotteryTypeEnum && OLOTTERYTYPE[oBetInfo.lotteryTypeEnum].bgcClass"></div>
					<span class="home_betInfo_title_a">{{fnLotteryType(oBetInfo.lotteryTypeEnum)}}</span>
					<span class="home_betInfo_title_b" v-if="oBetInfo.lotteryTypeEnum !== 'BJDC'">混合过关</span>
				</div>
				<div class="home_betInfo_time">
					截止时间：{{oBetInfo.expiredTime}}
				</div>
				<div class="home_betInfo_user">
					<span v-for="(item, index) in oBetInfo.mixTypeList" :key="index">{{item}}</span>
				</div>
			</div>
		</div>
	</h-dialog-a>
</template>

<script>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRouter
	} from 'vue-router';
	import {
		apiGetBetContent,
		apiGetPlatformPlanDetail
	} from '@/api/other';
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	import {
		fnFormatData
	} from "@/utils/public.js";
	import {
		fnLotteryType
	} from "@/utils/project.js";
	import {
		olotteryType as OLOTTERYTYPE,
		bjdcPlayWay as OPTIONS_PLAYWAY
	} from "@/utils/options.js";
	import defaultAvatar from "@/assets/images/img_032.png";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";
	export default {
		name: "TestClipboard",
		components: {
			HDialogA
		},
		setup() {
			const router = useRouter();
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;

			const oDialogA = reactive({
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "立即跟单"
			});
			// 跟单方案信息
			const oPlanInfo = ref({
				planDetail: {},
				roleDetail: {}
			});
			const oDialogB = reactive({
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "立即付款"
			});
			// 投注信息
			const oBetInfo = ref({});
			// 获取剪切板内容
			const fGetClipboard = () => {
				const Text = navigator.clipboard.readText();
				let Type = "NOBET";
				Text.then(text => {
					console.log("剪切板内容：" + text);
					if (text) {
						let tLogo = text.substring(0, 3);
						if (tLogo === "BET") {
							Type = tLogo;
							return apiGetBetContent({
								key: text
							});
						} else {
							return apiGetPlatformPlanDetail({
								currentRoleId: UserInfo.roleId,
								planId: text
							});
						}
					}
				}).then(res => {
					console.log(res);
					if (Type === "BET") {
						if (res && res.result) {
							console.log(1)
							let tData = res.result;
							console.log(tData);
							let tBetInfo = JSON.parse(tData);
							console.log(tBetInfo)
							oBetInfo.value = tBetInfo;
							oBetInfo.value.mixTypeList = tBetInfo.mixTypes.split("^");
							oDialogB.showDialog = true;
						}
					} else {
						if (res && res.result) {
							oPlanInfo.value.planDetail = res.result.planDetail ? res.result.planDetail : {};
							oPlanInfo.value.planDetail.mixTypeList = oPlanInfo.value.planDetail.mixTypes.split(
								"^");
							oPlanInfo.value.roleDetail = res.result.roleDetail ? res.result.roleDetail : {};
							oDialogA.showDialog = true;
						}
					}
					navigator.clipboard.writeText('');

				}).catch(err => {
					console.error('Failed to read clipboard contents: ', err);
				});
			};
			// 弹框关闭前
			const fBeforeClose = (action, oDialog, fn) => {
				console.log(action, oDialog, fn)
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						router.push({
							path: '/planDetail',
							query: {
								copyPlanId: oPlanInfo.value.planDetail.id
							}
						})
						// 跳转至跟单页面
						oDialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oDialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogB") {
					if (action === "confirm") {
						let tBetInfo = oBetInfo.value;
						store.dispatch("allPlay/UBETSelected", tBetInfo);
						// 跳转至投注页面
						oDialogB.showDialog = false;
						fn(false)
						if (tBetInfo.lotteryTypeEnum === "JCZQ") {
							router.push({
								path: '/bet',
							});
						} else if (tBetInfo.lotteryTypeEnum === "BJDC") {
							let t_Str = "";
							for (let Key in OPTIONS_PLAYWAY) {
								if (OPTIONS_PLAYWAY[Key].code === tBetInfo.lotterySubTypeEnum) {
									t_Str = Key
								}
							}
							if (t_Str) {
								router.push({
									path: '/betBD',
									query: {
										playWay: t_Str
									}
								});
							}
						} else {
							router.push({
								path: '/betLQ',
							});
						}
					} else if (action === "cancel") {
						oDialogB.showDialog = false;
						fn(false)
					}
				}
			};

			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				fLeft,

				oDialogA,
				oPlanInfo,

				oDialogB,
				oBetInfo,

				defaultAvatar,
				fGetClipboard,
				fBeforeClose,
				
				fnFormatData,
				fnLotteryType,
				
				OLOTTERYTYPE
			}
		}
	}
</script>

<style>
</style>
