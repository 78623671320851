<template>
	<div class="testTemplateCache app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="模版--缓存" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="testTemplateCache_body app_scroll">
			<van-stepper v-model="refA" />


			<van-cell-group>
				<van-cell title="store数据使用computed">
					<template #label>
						{{oNoticeDetailA}}
					</template>
				</van-cell>
				<van-cell title="store数据直接赋值">
					<template #label>
						{{oNoticeDetailB}}
					</template>
				</van-cell>
			</van-cell-group>
			<van-button type="primary" @click="fUpdate">更新Store信息</van-button>
			<van-button type="primary" @click="fEmpty">清空</van-button>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		computed,
		onActivated,
		inject
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	export default {
		name: "TestTemplateCache",
		setup() {
			const route = useRoute();
			const router = useRouter();
			const store = useStore(); // 使用useStore方法
			const fGlobalReload = inject("fGlobalReload");
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
				}
			});

			const refA = ref(0);
			console.log("使用方式：" + refA.value)

			const reactiveA = reactive({
				key: 0
			});
			console.log("使用方式：" + reactiveA.key)

			const oNoticeDetailA = computed(() => {
				return store.state.other.noticeDetail;
			});
			console.log(oNoticeDetailA.value);

			const oNoticeDetailB = store.state.other.noticeDetail;
			console.log(oNoticeDetailB);
			
			// 清空
			const fEmpty = () => {
				store.dispatch("other/UNoticeDetail", {})
			}
			// 更新
			const fUpdate = () => {
				store.dispatch("other/UNoticeDetail", {
					a: 1,
					b: 2
				})
			};
			const fGoPath = (path) => {
				router.push({
					path
				})
			};

			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				refA,
				fEmpty,
				fUpdate,
				
				oNoticeDetailA,
				oNoticeDetailB,

				fGoPath,
				fLeft,
			}
		}
	}
</script>

<style scoped lang="scss">
</style>
