<template>
	<div class="testJs app_flex">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar title="JS方法测试页面" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="testJs app_scroll">
			<van-button type="primary" @click="fnJumpApp">调用外部js方法需要return</van-button>
			<van-button type="primary" @click="fnAntiShake">防抖测试</van-button>
		</div>
	</div>
</template>

<script>
	import {
		fnJumpApp
	} from "@/utils/public.js";
	import {
		fnIsHasHistory
	} from "@/utils/router.js";
	export default {
		name: "TestJs",
		setup() {
			const fnAntiShake = () => {
				console.log("1111111111111")
			};
			const fLeft = () => {
				fnIsHasHistory("/");
			};
			return {
				fnJumpApp,
				fnAntiShake,
				fLeft
			}
		}
	}
</script>

<style>
</style>
